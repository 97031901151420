const echarts = require('echarts');

export const bossCountByYear = function(p) {
    var myChart = echarts.init(document.querySelector(p.doc));
    myChart.setOption({
        grid: {
            left: '4%',
            right: '1%',
            bottom: '10%',
            top: '15%'
        },
        legend: {
            data: '销售台数'
        },
        xAxis: {
            type: 'category',
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            /* axisLine:{
                 symbol: ['none', 'path://M5,20 L5,5 L8,8 L5,2 L2,8 L5,5 L5.3,6 L5.3,20 '],
                 symbolOffset: 10,
                 symbolSize: [35,38]
             }*/
        },
        yAxis: [{
            type: 'value',
            name: '数量/台',
            axisLabel: {
                formatter: '{value}'
            },
            minInterval: 1,
            show: true,
            /*axisLine:{
                symbol: ['none', 'path://M5,20 L5,5 L8,8 L5,2 L2,8 L5,5 L5.3,6 L5.3,20 '],
                symbolOffset: 15,
                symbolSize: [35,38]
            }*/
        }],
        series: [{
            barWidth: '30',
            data: p.data,
            type: 'bar',
            itemStyle: {
                normal: {
                    //这里是重点
                    color: '#437EE6'
                }
            },
        }],
        toolbox: {
            show: false,
            /*orient: 'top',
            top: 'top',*/
            feature: {
                mark: { show: true },
                dataView: { show: false, readOnly: false },
                magicType: { show: true, type: ['line', 'bar', ] },
                /*restore: {show: true},*/
                saveAsImage: { show: true }
            }
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        }

    });
    window.addEventListener("resize", function() {
        myChart.resize()
    })
}
export const bossCountMoneyByYear = function(p) {
        var myChart = echarts.init(document.querySelector(p.doc));
        myChart.setOption({
            grid: {
                left: '7%',
                right: '1%',
                bottom: '10%',
                top: '15%'
            },
            xAxis: {
                type: 'category',
                data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
            },
            yAxis: {
                type: 'value',
                name: '金额/元',
                axisLabel: {
                    formatter: '{value}'
                }
            },
            series: [{
                barWidth: '30',
                data: p.data,
                type: 'bar',
                itemStyle: {
                    normal: {
                        //这里是重点
                        color: '#437EE6'
                    }
                },
            }],
            toolbox: {
                show: false,
                /*orient: 'top',
                top: 'top',*/
                feature: {
                    mark: { show: true },
                    dataView: { show: false, readOnly: false },
                    magicType: { show: true, type: ['line', 'bar', ] },
                    /*restore: {show: true},*/
                    saveAsImage: { show: true }
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            }
        })
        window.addEventListener("resize", function() {
            myChart.resize()
        })
    }
    //华西折线图
export const huaxi_line = function(p) {
        var myChart = echarts.init(document.querySelector(p.doc));
        myChart.setOption({
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: p.dateList
            },
            yAxis: {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: 'dashed', //默认实线，dashed虚线
                    }
                },
                minInterval: 1000,
            },
            tooltip: {
                trigger: 'axis',
                formatter(params) {
                    let year = new Date().getFullYear();
                    let month = new Date().getMonth() + 1;
                    if (params[0].axisValue.slice(0, params[0].axisValue.length - 1) > month) {
                        year = year - 1;
                    }
                    return `
                    <div style='color:#fff;min-width:210px;display:flex;flex-direction: column;'>
                        <div>${year}-${params[0].axisValue}</div>
                        <div style='display:flex;align-items:center;margin:5px 0'>
                            <div style='width:12px;height:12px;border-radius:50%;background:#108ee9;margin:0 8px'></div>
                            <div style='width:130px;text-align:right;'>本月应收款：</div>
                            <div>${params[0].value} 元</div>
                        </div>
                        <div style='display:flex;align-items:center;margin:5px 0'>
                            <div style='width:12px;height:12px;border-radius:50%;background:#2fc25b;margin:0 8px'></div>
                            <div style='width:130px;text-align:right;'>本年度应收结余款：</div>
                            <div>${params[1].value} 元</div>
                        </div>
                    </div>
                `
                }
            },
            grid: {
                left: 60,
                top: 40,
                right: 30,
            },
            series: [{
                    data: p.leaseReceivablesList,
                    type: 'line',
                    name: "本月应收款",
                    lineStyle: {
                        color: '#1890ff' //改变折线颜色
                    }

                },
                {
                    data: p.thisYearDepositReceivablesList,
                    type: 'line',
                    name: "本年度应收结余款",
                    lineStyle: {
                        color: '#2fc25b' //改变折线颜色
                    }
                },
            ]
        });
        window.addEventListener("resize", function() {
            myChart.resize()
        })
    }
    //华西柱图
export const huaxi_bar = function(p) {
    var myChart = echarts.init(document.querySelector(p.doc));
    myChart.setOption({
        color: ['#3ba1ff'],
        xAxis: {
            type: 'category',
            data: p.dateList
        },
        yAxis: {
            type: 'value',
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'dashed', //默认实线，dashed虚线
                }
            },
            minInterval: 5,
        },
        tooltip: {
            trigger: 'axis',
            formatter(params) {
                let year = new Date().getFullYear();
                let month = new Date().getMonth() + 1;
                if (params[0].axisValue.slice(0, params[0].axisValue.length - 1) > month) {
                    year = year - 1;
                }
                return `
                    <div style='color:#fff;min-width:210px;'>
                    <div>${year}-${params[0].axisValue}</div>
                        <div style='display:flex;align-items:center;margin:5px 0'>
                            <div style='width:12px;height:12px;border-radius:50%;background:#108ee9;margin:0 8px'></div>
                            <div>${params[0].value} 台</div>
                        </div>
                    </div>
                `
            }
        },
        grid: {
            left: 60,
            top: 35,
            right: 30,
            bottom: 40
        },
        series: [{
            data: p.dataList,
            type: 'bar',
            barWidth: 25
        }]
    })
    window.addEventListener("resize", function() {
        myChart.resize()
    })
}