<template>
    <div class="body">
        <div class="top">
            <div class="top-left" ref="contentScroll">
                <div class="top-left-header">
                    发货情况
                </div>
                <div style="margin: 0 10px">
                    <el-divider></el-divider>
                </div>
                <div class="top-main">
                    <table style="width: 95%;">
                        <tr>
                            <td class="data-header">今日发货台数（台）</td>
                            <td class="data-header">本月发货台数（台）</td>
                            <td class="data-header">今年塔机销售台数（台）</td>
                            <td class="data-header">今年塔机租赁台数（台）</td>
                            <td class="data-header">今年升降机销售台数（台）</td>
                            <td class="data-header">今年升降机租赁台数（台）</td>
                            <td class="data-header">累计发货台数（台）</td>
                        </tr>
                        <tr>
                            <td class="data-number">{{todayCount}}</td>
                            <td class="data-number">{{monthCount}}</td>
                            <td class="data-number">{{yearTowerCount}}</td>
                            <td class="data-number">{{leaseTowerCount}}</td>
                            <td class="data-number">{{yearLifterCount}}</td>
                            <td class="data-number">{{leaseLifterCount}}</td>
                            <td class="data-number">{{totalCount}}</td>
                        </tr>
                    </table>
                    <div class="table-data">
                        <div class="table-style">
                            <table class="table-data-style">
                                <thead style="height: 50px">
                                <tr>
                                    <td>项目名称</td>
                                    <td>发货日期</td>
                                    <td>出库单号</td>
                                    <td>销售类型</td>
                                    <td>设备类型</td>
                                    <td>设备IMEI</td>
                                    <td>SIM卡号</td>
                                </tr>
                                </thead>
                                <tbody style="font-size:15px">
                                <tr v-for="(item, index) in tableData" :key="index">
                                    <td height="14%">
                                        {{item.projectName}}
                                    </td>
                                    <td>
                                        {{item.createTime?item.createTime.slice(0,10):''}}
                                    </td>
                                    <td>
                                        {{item.outListNum}}
                                    </td>
                                    <td>
                                        {{item.applyType==0?'试用协议':(item.contractType == 0?'销售合同':item.contractType ==
                                        1?'技术服务合同':'')}}
                                    </td>
                                    <td>
                                        <div v-if="item.outListNum">
                                            {{item.deviceType == 0?'塔机':'升降机'}}
                                        </div>
                                    </td>
                                    <td>
                                        {{item.imeiNum}}
                                    </td>
                                    <td>
                                        {{item.simNum}}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="page-style">
                            <el-pagination
                                    @current-change="handleCurrentChange"
                                    :page-size="pageSize"
                                    layout="prev, pager, next"
                                    :total="pageTotal">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
            <div class="top-right">
                <div class="top-box">
                    <div class="box-title">回款率</div>
                    <div class="percentage-style">
                        <div class="percentage">{{(gatherPercentage*100).toFixed(2)}}%</div>
                        <div style="margin: 0 20px;margin-top:25px">
                            <el-progress :stroke-width="26"
                                         :show-text="false"
                                         color="#437EE6"
                                         
                                         :percentage="((gatherPercentage*100).toFixed(2))*1"></el-progress>
                        </div>
                        <!-- <div style="margin: 0px 20px 0px 20px">
                            <el-divider></el-divider>
                        </div> -->
                    </div>
                    <ul class="rightUl">
                        <li>
                            <p>回款金额</p>
                            <p style="color:green">{{gatherTotal}}</p>
                            <p style="color:green">({{moneyArr[0].num}} {{moneyArr[0].unit}})</p>
                        </li>
                        <li>
                            <p>未回款金额</p>
                            <p style="color:red">{{gatherNone}}</p>
                            <p style="color:red">({{moneyArr[1].num}} {{moneyArr[1].unit}})</p>
                        </li>
                        <li>
                            <p>销售回款金额</p>
                            <p style="color:green">{{saleReTotal}}</p>
                            <p style="color:green">({{moneyArr[2].num}} {{moneyArr[2].unit}})</p>
                        </li>
                        <li>
                            <p>销售未回款金额</p>
                            <p style="color:red">{{saleNoReTotal}}</p>
                            <p style="color:red">({{moneyArr[3].num}} {{moneyArr[3].unit}})</p>
                        </li>
                        <li>
                            <p>租赁回款金额</p>
                            <p style="color:green">{{leaseReTotal}}</p>
                            <p style="color:green">({{moneyArr[4].num}} {{moneyArr[4].unit}})</p>
                        </li>
                        <li>
                            <p>租赁未回款金额</p>
                            <p style="color:red">{{leaseNoReTotal}}</p>
                            <p style="color:red">({{moneyArr[5].num}} {{moneyArr[5].unit}})</p>
                        </li>
                    </ul>
                    <!-- <div>
                        <div>
                            <span>回款金额:</span>
                        </div>
                        <div style="margin:3px 0">
                            <span style="color: green"> {{gatherTotal}}</span>
                        </div>
                        <div>
                            <span style="font-size:12px;color: green">( {{moneyArr[0].num}} {{moneyArr[0].unit}} )</span>
                        </div>
                    </div>
                    <div>
                        <div>
                            <span>未回款金额:</span>
                        </div>
                        <div style="margin:3px 0">
                            <span style="color: red"> {{gatherNone}}</span>
                        </div>
                        <div>
                            <span style="font-size:12px;color: red">( {{moneyArr[1].num}} {{moneyArr[1].unit}} )</span>
                        </div>
                    </div> -->
                </div>
                <!-- <div class="top-box" style="margin-top: 10px">
                    <div class="box-title">发货率</div>
                    <div class="percentage-style">
                        <div class="percentage">{{(sendPercentage*100).toFixed(2)}}%</div>
                        <div style="margin: 0 20px">
                            <el-progress :stroke-width="26"
                                         :show-text="false"
                                         color="#437EE6"
                                         :percentage="((sendPercentage*100).toFixed(2))*1"></el-progress>
                        </div>
                        <div style="margin: 10px 20px 0px 20px">
                            <el-divider></el-divider>
                        </div>
                    </div>
                    <div class="box-bottom">
                        <div>
                            <div>
                                <span>已发货:</span>
                            </div>
                            <div style="margin:3px 0">
                                <span style="color: green"> {{sendTotal}}</span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <span>未发货:</span>
                            </div>
                            <div style="margin:3px 0">
                                <span style="color: red"> {{sendNone}}</span>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="bottom">
            <div class="bottom-left">
                <div class="tab-title">
                    <span class="click-sale"
                          :style="{margin: '5px 10px', color: saleNumColor}"
                          @click="handleSaleTotal">
                        发货台数
                    </span>
                    <div @click="handleSaleMoney"
                         class="click-sale"
                         :style="{margin: '5px 10px', color: saleMoneyColor}"
                    >
                        回款金额
                    </div>
                </div>
                <div class="border-style"></div>
                <div class="echarts-style-quantity">
                    <div v-show="isShow" class="quantity-number">
                        <div class="echarts-title">
                            发货趋势
                        </div>
                        <div class="echarts-style quantity">

                        </div>
                    </div>
                    <div v-show="!isShow" class="quantity-number">
                        <div class="echarts-title">
                            回款趋势
                        </div>
                        <div class="echarts-style money">

                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-right">
                <div class="search">
                    <div @click="changeMonth" class="search-btn"
                         :style="{ color: changeColorM}">
                        本月
                    </div>
                    <div @click="changeYear" class="search-btn"
                         :style="{paddingRight: '20px', color: changeColorY}">
                        全年
                    </div>
                    <div style="margin-top: 10px">
                        <el-date-picker
                                size="mini"
                                v-model="timeRange"
                                style="width: 90%;"
                                type="daterange"
                                @change="searchByTime"
                                range-separator="-"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd">
                        </el-date-picker>
                    </div>
                </div>
                <div class="border-style"></div>
                <div style="margin: 0 10px 0 10px">
                    <div class="bottom-title">
                        销售人员榜单
                    </div>
                </div>
                <div class="table-sale" id="div-height">
                    <div class="vatual-table">
                        <div class="first-list">
                            <div class="first-box second" v-if="topThree[1]">
                                <img :src="secondPath" width="60%" height="60%"
                                     style="margin-left: 19%">
                                <div class="text-show">
                                    <div>
                                        <span>{{topThree[1].userName}}</span>
                                    </div>
                                    <div>
                                        <span>{{(topThree[1].saleAmount/100).toFixed(2)}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="first-box first" v-if="topThree[0]">
                                <img :src="firstPath" width="65%" height="65%"
                                     style="margin-left: 19%">
                                <div class="text-show">
                                    <div>
                                        <span>{{topThree[0].userName}}</span>
                                    </div>
                                    <div>
                                        <span>{{(topThree[0].saleAmount/100).toFixed(2)}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="first-box third" v-if="topThree[2]">
                                <img :src="thirdPath" width="60%" height="60%"
                                     style="margin-left: 19%">
                                <div class="text-show">
                                    <div>
                                        <span>{{topThree[2].userName}}</span>
                                    </div>
                                    <div>
                                        <span>{{(topThree[2].saleAmount/100).toFixed(2)}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table-list">
                            <div class="text-display" v-for="(item , i) in salesData" :key="i">
                                <div class="sort-salesman">
                                    <div>{{item.index}}</div>
                                </div>
                                <div class="text-style name">
                                    {{item.userName}}
                                </div>
                                <div class="text-style amount">
                                    {{(item.saleAmount/100).toFixed(2)}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        bossCountByYear, bossCountMoneyByYear
    } from '../../assets/js/echarts'
    import changeMoney from '../../assets/js/money'
    export default {
        data() {
            return {
                moneyArr:[{},{},{},{},{},{}],
                tableData: [],
                salesData: [],
                topThree: [],
                timeRange: [],
                todayCount: 0,
                monthCount: 0,
                totalCount: 0,
                yearTowerCount:'',
                yearLifterCount:'',
                leaseTowerCount:'',
                leaseLifterCount:'',
                statisticsHeight: '',
                statisticsWidth: '',
                isShow: true,
                pageSize: 7,
                gatherPercentage: 0,
                gatherTotal: 0,
                gatherNone: 0,
                saleReTotal:0,
                saleNoReTotal:0,
                leaseReTotal:0,
                leaseNoReTotal:0,
                sendPercentage: 0,
                sendTotal: 0,
                sendNone: 0,
                pageTotal: 0,
                marginTop: '10px',
                changeColorM: '#437EE6',
                changeColorY: '#4D4D4D',
                saleNumColor: '#437EE6',
                saleMoneyColor: '#4D4D4D',
                screenWidth: 0,
                firstPath: require("../../assets/images/sort/first_place.png"),
                secondPath: require("../../assets/images/sort/second_place.png"),
                thirdPath: require("../../assets/images/sort/third_place.png"),
                backColor: 'red',
            }
        },
        methods: {
            changeMonth() {
                this.topThree = [];
                this.salesData = [];
                this.timeRange = [];
                this.changeColorY = '#4D4D4D'
                this.changeColorM = '#437EE6'
                var date = new Date();
                var firstDay = this.getFirstDate(date);
                var endDay = this.getLastDate(date)
                this.$api.getSalesSort({startDate: firstDay, endDate: endDay}).then(res => {
                    if (res.data.length > 3){
                        for (var i = 0; i < 3; i++) {
                            this.topThree.push(res.data[i])
                        }
                        for (var i = 3; i < res.data.length; i++) {
                            res.data[i].index = i + 1
                            this.salesData.push(res.data[i])
                        }
                    }else {
                        this.topThree = res.data
                    }
                })
            },
            getFirstDate(date) {
                var firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
                return firstDate.getFullYear() + '-'
                    + (firstDate.getMonth() + 1 < 10 ? ('0' + (firstDate.getMonth() + 1)) : (firstDate.getMonth() + 1)) + '-'
                    + (firstDate.getDate() + 1 < 10 ? ('0' + firstDate.getDate()) : firstDate.getDate());
            },
            getLastDate(date) {
                var lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                return lastDate.getFullYear() + '-'
                    + (lastDate.getMonth() + 1 < 10 ? ('0' + (lastDate.getMonth() + 1)) : (lastDate.getMonth() + 1)) + '-'
                    + (lastDate.getDate() + 1 < 10 ? ('0' + lastDate.getDate()) : lastDate.getDate());
                ;
            },
            changeYear() {
                this.topThree = [];
                this.salesData = [];
                this.timeRange = [];
                this.changeColorM = '#4D4D4D'
                this.changeColorY = '#437EE6'
                var date = new Date();
                var firstDay = date.getFullYear() + '-01-01';
                var endDay = date.getFullYear() + '-12-31'
                this.$api.getSalesSort({startDate: firstDay, endDate: endDay}).then(res => {
                    if (res.data.length > 3){
                        for (var i = 0; i < 3; i++) {
                            this.topThree.push(res.data[i])
                        }
                        for (var i = 3; i < res.data.length; i++) {
                            res.data[i].index = i + 1
                            this.salesData.push(res.data[i])
                        }
                    }else {
                        this.topThree = res.data
                    }
                })
            },
            handleSaleTotal() {
                this.saleMoneyColor = '#4D4D4D'
                this.saleNumColor = '#437EE6'
                this.isShow = true;
                this.changeEcharts(1);
            },
            handleSaleMoney() {
                this.saleNumColor = '#4D4D4D'
                this.saleMoneyColor = '#437EE6'
                this.isShow = false;
                this.changeEcharts(0);
            },
            searchByTime() {
                this.topThree = [];
                this.salesData = [];
                this.changeColorM = '#4D4D4D'
                this.changeColorY = '#4D4D4D'
                this.$api.getSalesSort({startDate: this.timeRange[0], endDate: this.timeRange[1]}).then(res => {
                    if (res.data.length > 3){
                        for (var i = 0; i < 3; i++) {
                            this.topThree.push(res.data[i])
                        }
                        for (var i = 3; i < res.data.length; i++) {
                            res.data[i].index = i + 1
                            this.salesData.push(res.data[i])
                        }
                    }else {
                        this.topThree = res.data
                    }
                })
            },
            handleCurrentChange(val) {
                this.$api.getPageSend({pageIndex: val, pageSize: 7, isBoss: 1}).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                    }
                    for (var i = res.data.content.length; i < 7; i++) {
                        this.tableData.push({});
                    }
                })

            },
            changeEcharts(val) {
                var showAmountData = []
                var showCountData = []
                this.$api.getSalesIndex({pageSize: 7, isBoss: 1}).then(
                    res => {
                        for (var item of res.data.saleData) {
                            for (var itemKey in item) {
                                if (itemKey == 'saleAmount') {
                                    showAmountData.push(item[itemKey] / 100)
                                } else if (itemKey == 'sendCount') {
                                    showCountData.push(item[itemKey])
                                }
                            }
                        }
                        if (val == 1) {
                            setTimeout(() => {
                                bossCountByYear({doc: '.quantity', data: showCountData})
                            },)
                        } else {
                            setTimeout(() => {
                                bossCountMoneyByYear({doc: '.money', data: showAmountData})
                            },)
                        }
                    })
            }
        },
        bubbleSortByAmount(arr) {
            let len = arr.length;
            for (let i = 0; i < len; i++) {
                for (let j = 0; j < len - 1 - i; j++) {
                    if (arr[j].saleAmount > arr[j + 1].saleAmount) {        //相邻元素两两对比
                        [arr[j + 1].saleAmount, arr[j].saleAmount]
                            = [arr[j].saleAmount, arr[j + 1].saleAmount];
                    }
                }
            }
            return arr;
        },
        bubbleSortByCount(arr) {
            let len = arr.length;
            for (let i = 0; i < len; i++) {
                for (let j = 0; j < len - 1 - i; j++) {
                    if (arr[j].sendCount > arr[j + 1].sendCount) {        //相邻元素两两对比
                        [arr[j + 1].sendCount, arr[j].sendCount]
                            = [arr[j].sendCount, arr[j + 1].sendCount];
                    }
                }
            }
            return arr;
        },
        watch: {}
        ,
        created() {
            var index = 3;
            var showData = []
            this.$api.getSalesIndex({pageSize: 7, isBoss: 1}).then(
                res => {
                    if (res.code == 200) {
                        res.data.shouldReceiveAmount = res.data.shouldReceiveAmount || 0;
                        res.data.receiveAmount = res.data.receiveAmount || 0;
                        res.data.shouldCount = res.data.shouldCount || 0;
                        res.data.totalCount = res.data.totalCount || 0;
                        this.gatherTotal = (res.data.receiveAmount / 100).toFixed(2)
                        this.gatherNone = ((res.data.shouldReceiveAmount - res.data.receiveAmount) / 100).toFixed(2)
                        this.saleReTotal = (res.data.saleAmount / 100).toFixed(2)
                        this.saleNoReTotal = ((res.data.shouldSaleAmount - res.data.saleAmount) / 100).toFixed(2)
                        this.leaseReTotal = (res.data.leaseAmount / 100).toFixed(2)
                        this.leaseNoReTotal = ((res.data.shouldLeaseAmount - res.data.leaseAmount) / 100).toFixed(2)
                        this.moneyArr = [
                            {
                                ...changeMoney(this.gatherTotal)
                            },
                            {
                                ...changeMoney(this.gatherNone)
                            },
                            {
                                ...changeMoney(this.saleReTotal)
                            },
                            {
                                ...changeMoney(this.saleNoReTotal)
                            },
                            {
                                ...changeMoney(this.leaseReTotal)
                            },
                            {
                                ...changeMoney(this.leaseNoReTotal)
                            }
                        ]
                        if (res.data.shouldReceiveAmount > 0) {
                            this.gatherPercentage = res.data.receiveAmount / res.data.shouldReceiveAmount;
                        } else {
                            this.gatherPercentage = 0
                        }
                        this.sendTotal = res.data.totalCount
                        this.sendNone = res.data.shouldCount - res.data.totalCount
                        if (res.data.shouldCount > 0) {
                            this.sendPercentage = res.data.totalCount / res.data.shouldCount;
                        } else {
                            this.gatherPercentage = 0
                        }
                        this.pageTotal = res.data.deviceOutPage.totalElements;
                        this.todayCount = res.data.todayCount
                        this.monthCount = res.data.monthCount

                        this.totalCount = res.data.totalCount
                        this.yearTowerCount = res.data.yearTowerCount
                        this.yearLifterCount = res.data.yearLifterCount
                        this.leaseTowerCount = res.data.leaseTowerCount
                        this.leaseLifterCount = res.data.leaseLifterCount
                        this.tableData = res.data.deviceOutPage.content;
                        for (var i = this.tableData.length; i < 7; i++) {
                            this.tableData.push({})
                        }
                        if (res.data.saleList.length > 3){
                            for (var i = 0; i < 3; i++) {
                                res.data.saleList[i].index = i + 1
                                this.topThree.push(res.data.saleList[i])
                            }
                            for (var i = 3; i < res.data.saleList.length; i++) {
                                res.data.saleList[i].index = i + 1
                                this.salesData.push(res.data.saleList[i])
                            }
                        }else {
                            this.topThree = res.data.saleList
                        }
                        for (var item of res.data.saleData) {
                            for (var itemKey in item) {
                                if (itemKey == 'sendCount')
                                    showData.push(item[itemKey])
                            }
                        }
                        setTimeout(() => {
                            bossCountByYear({doc: '.quantity', data: showData})
                        },)
                    }
                })

        }
    }
</script>

<style scoped lang="scss">
    .body {
        width: 100%;
        height: 100%;
    }

    .top {
        width: 100%;
        margin-bottom: 10px;
        margin-top: 5px;
        height: 58%;
        display: flex;
        flex-direction: row;
    }

    .top-left {
        width: 77%;
        height: 100%;
        margin-right: 10px;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
    }

    .top-left-header {
        width: 120px;
        height: 18px;
        font-size: 20px;
        font-family: Source Han Sans CN;
        line-height: 31px;
        color: #333333;
        opacity: 1;
        font-weight: bold;
        margin: 10px;
        letter-spacing: 3px;
    }

    .el-divider--horizontal {
        margin-bottom: 10px !important;
    }

    .table-data {
        //height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    td {
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
        text-align: center;
    }

    .table-style {
        //height: 90%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        overflow: auto;
    }

    .table-data-style {
        border-collapse: collapse;
        width: 100%;
        min-height: 100%;
        height: 100%;
        flex: 1;
        //height: 100%;
        overflow-y: auto;
        td{
            border: 1px solid #eee;
            font-size: 15px;
        }
    }

    .table-data-style thead {
        text-align: center;
        font-weight: 500;
        color: #909399;
        font-size: 16px;
    }

    .top-main {
        margin: 0 10px;
        //height: 67%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .data-header {
        width: 135px;
        height: 15px;
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 26px;
        color: #808080;
        opacity: 1;
    }

    .data-number {
        width: 53px;
        height: 30px;
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 51px;
        color: #437EE6;
        opacity: 1;
        text-align: center;
    }

    .page-style {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 12%;
    }

    .top-right {
        width: 23%;
        height: 100%;
        display: flex;
    }

    .top-box {
        height: 100%;
        width: 100%;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
    }

    .percentage {
        width: 100%;
        font-size: 50px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        height: 48%;
        color: #437EE6;
        text-align: center;
        margin-bottom: 1%;
        margin-top: -5px;
    }

    .percentage-style {
        height: 23%;
    }

    .box-bottom {
        font-size: 15px;
        font-weight: bold;
        line-height: 20px;
        color: #808080;
        display: flex;
        width: 70%;
        justify-content: space-between;
        margin: 0 10%;
        align-items: center;
    }
    .box-title {
        font-size: 20px;
        font-weight: bold;
        height: 5%;
        margin: 0 10px;
        padding: 10px 0;
    }
    .rightUl{
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        li{
            list-style: none;
            width: 49%;
            display: flex;
            flex-direction: column;
            :nth-child(1){
                font-weight: bold;
                font-size: 17px;
            }
            :nth-child(2){
                padding-top: 5px;
                box-sizing: border-box;
            }
            :nth-child(3){
                font-weight: bold;
                font-size: 13px;
                padding-top: -20px;
            }
        }
        p{
            //flex: 1;
            height: 30px;
            //font-size: 15px;
            font-weight: bold;
            color: #808080;
            padding-left: 7%;
            //border: 1px solid red;
        }
    }
    .el-progress-bar__outer  {
        border-radius:  0 !important;
    }

    .el-progress-bar__inner  {
        border-radius:  0;
    }

    .bottom {
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: row;
        background-color: #fff;
    }

    .bottom-left {
        width: 77%;
        height: 100%;
        margin-right: 10px;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
    }

    .border-style {
        margin-top: 4px;
        border: 1px #dcdfe6 solid;
    }

    .tab-title {
        display: flex;
        flex-direction: row;
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #808080;
        height: 40px;
    }

    .click-sale {
        cursor: pointer;
        padding-top: 5px;
        width: 80px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 26px;
        color: #4D4D4D;
        opacity: 1;
    }

    .echarts-style-quantity {
        height: calc(100vh - 680px);
        margin: 10px 0;
    }

    .bottom-right {
        width: 23%;
        height: 100%;
        display: flex;
        flex-direction: column;

    }

    .search {
        display: flex;
        flex-direction: row;
        height: 40px;
    }

    .search-btn {
        padding-top: 10px;
        width: 20%;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 27px;
        color: #4D4D4D;
        cursor: pointer;
    }

    .bottom-title {
        width: 120px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 100%;
        color: #4D4D4D;
        padding: 10px 0;
    }

    .table-sale {
        //flex: 1;
        //height: calc(100vh - 700px);
        height: 24.8vh;
        margin: 0 2% 2% 2%;
        overflow-y: auto;
    }

    .quantity-number {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .echarts-title {
        width: 100%;
        height: 20px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 34px;
        color: #4D4D4D;
        text-align: center;
    }

    .echarts-style {
        flex: 1;
    }

    .sort-salesman {
        line-height: 20px;
        width: 27px;
        height: 20px;
        text-align: center;
        border-radius: 50%;
        color: black;
        font-size: 13px;
        background: #CCCCCC;
        margin: 0 10px;
    }

    .sort-salesmen {
        line-height: 20px;
        border-radius: 50%;
        color: #f0f0f0;
        background: #CCCCCC;
        margin: 0 10px;
    }

    .vatual-table {
        height: 24.6vh;
        display: flex;
        flex-direction: column;
    }

    .first-list {
        height: 110px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .table-list {
        flex: 1;
        overflow-y: auto;
    }
    .text-display {
        flex: 1;
        height: 24px;
        display: flex;
        flex-direction: row;
        margin-top:3.5%;
        overflow-y: scroll;
        overflow-x: auto;
    }

    .first-box {
        width: 30%;
        height: 60px;
    }

    .second {
        margin-right: 3.3%;
        margin-top: 1.5%;
        display: flex;
        flex-direction: column;
        height: 100%;
        text-align: center;
    }

    .first {
        display: flex;
        flex-direction: column;
        height: 100%;
        text-align: center;
    }

    .third {
        margin-left: 3.3%;
        display: flex;
        margin-top: 2%;
        flex-direction: column;
        height: 100%;
        text-align: center;
    }

    .text-show {
        height: 100%;
        display: flex;
        flex-direction: column;
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 20px;
        color: #999999;
    }

    .text-style{
        height: 20px;
        font-size: 16px;
        line-height: 20px;
        color: #666666;
        opacity: 1;
    }

    .name{
        width: 100px;
        text-align: center;
    }
    .amount{
        width: 100%;
        text-align: right;
    }
</style>
